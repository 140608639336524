import Logger from '../Logger.js';

const _osmRegex =
  /https:\/\/www\.openstreetmap\.org\/.*#.*map=\d+\/(-?[0-9.]+)\/(-?[0-9.]+)/;
const _googleMapsRegex =
  /https:\/\/www\.google\.com\/maps\/.*@(-?[0-9.]+),(-?[0-9.]+),\d+z/;
const _googleMapsSearchRegex =
  /https:\/\/www\.google\.com\/maps\/search\/\?api=1.*&query=(-?[0-9.]+),(-?[0-9.]+)/;
const _bingRegex =
  /https:\/\/www\.bing\.com\/maps\/.*\?.*cp=(-?[0-9.]+)~(-?[0-9.]+)/;
const _wegoRegex =
  /https:\/\/wego\.here\.com\/.*\?.*map=(-?[0-9.]+),(-?[0-9.]+)/;

/**
 * getCurrentPosition
 * Timeout is 12s
 * @returns {Promise<GeolocationCoordinates>} - see https://developer.mozilla.org/en-US/docs/Web/API/GeolocationCoordinates
 */
const getCurrentPosition = async () => {
  try {
    const { coords } = await new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 12000
      })
    );
    return coords;
  } catch (error) {
    if (error instanceof window.GeolocationPositionError) {
      error.name = [
        null,
        'NotAllowedError',
        'PositionUnavailableError',
        'TimeoutError'
      ][error.code];
    }
    Logger.warn('Geolocation::getCurrentPosition', error);
    throw error;
  }
};

/**
 * getPermissionStatus
 * @returns {Promise<"prompt"|"denied"|"granted">}
 */
const getPermissionStatus = async () => {
  try {
    const { state } = await navigator.permissions.query({
      name: 'geolocation'
    });
    return state;
  } catch (error) {
    Logger.warn('Geolocation::getPermissionStatus', error);
    return 'prompt';
  }
};

/**
 * generateLinkFromCoordinates
 * @param {number|string} latitude
 * @param {number|string} longitude
 * @returns {{ what3words: string, googleMaps: string, openStreetMap: string, bingMaps: string, wegoHere: string }}
 */
const generateLinkFromCoordinates = (latitude, longitude) => {
  return {
    what3words: `https://what3words.com/${latitude},${longitude}`,
    googleMaps: `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
    openStreetMap: `https://www.openstreetmap.org/?mlat=${latitude}&mlon=${longitude}#map=17/${latitude}/${longitude}`,
    bingMaps: `https://www.bing.com/maps/?cp=${latitude}~${longitude}&lvl=17`,
    wegoHere: `https://wego.here.com/?map=${latitude},${longitude},17,omv`
  };
};

/**
 * linkContainsCoordinates
 * @param {string} link
 * @returns {boolean}
 */
const linkContainsCoordinates = link => {
  const clearLink = decodeURIComponent(link);
  return [
    _googleMapsSearchRegex,
    _googleMapsRegex,
    _osmRegex,
    _bingRegex,
    _wegoRegex
  ].some(regex => regex.test(clearLink));
};

/**
 * parseCoordinatesFromLink
 * @param {string} link
 * @returns {{ latitude: number, longitude: number } | null}
 */
const parseCoordinatesFromLink = link => {
  const clearLink = decodeURIComponent(link);
  const regex = [
    _googleMapsSearchRegex,
    _googleMapsRegex,
    _osmRegex,
    _bingRegex,
    _wegoRegex
  ].find(testRegex => testRegex.test(clearLink));
  if (!regex) {
    return null;
  }
  const match = regex.exec(clearLink);
  return { latitude: Number(match[1]), longitude: Number(match[2]) };
};

const _instance = {
  getPermissionStatus,
  getCurrentPosition,
  linkContainsCoordinates,
  generateLinkFromCoordinates,
  parseCoordinatesFromLink
};

export default _instance;
