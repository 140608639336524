import Logger from './Logger.js';

import {
  StopPlaybackEvent,
  StartPlaybackEvent
} from './events/PlaybackEvents.js';

import {
  StartAnnotationEvent,
  StopAnnotationEvent
} from './events/AnnotationEvents.js';

import StfuEvent from './events/StfuEvent.js';
import IgnoreEvent from './events/IgnoreEvent.js';
import PublicEvent from './events/PublicEvent.js';
import PodiumEvent from './events/PodiumEvent.js';
import ForwardEvent from './events/ForwardEvent.js';
import SnapshotEvent from './events/SnapshotEvent.js';
import SetLayerEvent from './events/SetLayerEvent.js';
import SendChatEvent from './events/SendChatEvent.js';
import StopRtmpEvent from './events/StopRtmpEvent.js';
import SetLayoutEvent from './events/SetLayoutEvent.js';
import StartRtmpEvent from './events/StartRtmpEvent.js';
import RoomReceivedEvent from './events/RoomReceivedEvent.js';
import FetchRoomEvent from './events/FetchRoomEvent.js';
import DeleteFileEvent from './events/DeleteFileEvent.js';
import UploadFileEvent from './events/UploadFileEvent.js';
import ReceiveChatEvent from './events/ReceiveChatEvent.js';
import StartStreamEvent from './events/StartStreamEvent.js';
import ChangeStreamEvent from './events/ChangeStreamEvent.js';
import ReplaceStreamEvent from './events/ReplaceStreamEvent.js';
import ToggleCameraEvent from './events/ToggleCameraEvent.js';
import AcceptSessionEvent from './events/AcceptSessionEvent.js';
import StopRecordingEvent from './events/StopRecordingEvent.js';
import VoiceActivityEvent from './events/VoiceActivityEvent.js';
import StartRecordingEvent from './events/StartRecordingEvent.js';
import StartScreenCaptureEvent from './events/ScreenCaptureEvent.js';
import StartPresentingEvent from './events/StartPresentingEvent.js';
import StopPresentingEvent from './events/StopPresentingEvent.js';
import StopBroadcastsEvent from './events/StopBroadcastsEvent.js';
import ClearFrontLayerEvent from './events/ClearFrontLayerEvent.js';
import RequestGuestUserEvent from './events/RequestGuestUserEvent.js';
import SessionTerminationEvent from './events/SessionTerminationEvent.js';
import ParticipantListUpdateEvent from './events/ParticipantListUpdateEvent.js';
import { StartMixerEvent, UpdateMixerEvent } from './events/MixerEvents.js';
import { StartMediaEvent } from './events/MediaEvents.js';
import LockMeetingEvent from './events/LockMeetingEvent.js';
import TerminateMeetingEvent from './events/TerminateMeetingEvent.js';
import KickUserEvent from './events/KickUserEvent.js';
import VirtualBackgroundEvent from './events/VirtualBackgroundEvent.js';
import ScreenVideoChangeEvent from './events/ScreenVideoChangeEvent.js';
import ScreenVideoStoppedEvent from './events/ScreenVideoStoppedEvent.js';
import PresentationUpdateEvent from './events/PresentationUpdateEvent.js';
import RequestPermalinkMeetingInfoEvent from './events/RequestPermalinkMeetingInfoEvent.js';
import {
  StartPipCamEvent,
  StopPipCamEvent,
  UpdatePipCamEvent
} from './events/PipCamEvents.js';
import {
  AudioDeviceEndEvent,
  VideoDeviceEndEvent,
  AllDevicesEndEvent
} from './events/DeviceEndEvents.js';

/**
 * Event Handler uses the connection credentials for the room and
 * the user to setup a (monitored) connection, start a session and
 * listen for any events.
 **/
class EventHandler {
  constructor() {
    this.listeners = [];
    this._eyeson = null;
    this._session = null;
    this._monitor = null;
    this._connection = null;
    this._rtConnection = null;

    this.send = this.send.bind(this);
  }

  /* eslint-disable max-statements */
  destroy() {
    if (this._monitor) {
      this._monitor.destroy();
    }
    if (this._connection) {
      this._connection.close();
    }
    if (this._rtConnection) {
      this._rtConnection.close();
    }
    if (this._session) {
      if (this._session.vbgMixer) {
        this._session.vbgMixer.stopOriginalStream();
        this._session.vbgMixer.terminate();
        this._session.vbgMixer.destroy();
      }
      this._session.end();
    }
    this._api = null;
    this._session = null;
    this._monitor = null;
    this._connection = null;
    this._rtConnection = null;
  }
  /* eslint-enable max-statements */

  set connection(con) {
    if (this._connection) {
      Logger.error(
        'A connection is already set. If you are trying to start a new' +
          ' session you have to end the current one first.'
      );
      return;
    }
    this._connection = con;
    this._connection.onMessage(this.send);
  }

  get connection() {
    return null;
  }

  set rtConnection(con) {
    if (this._rtConnection) {
      Logger.error(
        'A real-time connection is already set. If you are trying to start' +
          ' a new session you have the close the current before.'
      );
      return;
    }
    this._rtConnection = con;
    this._rtConnection.onMessage(this.send);
  }

  get rtConnection() {
    return null;
  }

  set monitor(mon) {
    if (this._monitor) {
      Logger.error(
        'A monitor is already set. If you are trying to start a new' +
          ' session you have to end the current one first.'
      );
      return;
    }
    this._monitor = mon;
    this._monitor.onEvent(this.send);
  }

  get monitor() {
    return this._monitor;
  }

  set session(ses) {
    if (this._session) {
      Logger.error(
        'A session is already set. If you are trying to start a new' +
          ' session you have to end the current one first.'
      );
      return;
    }
    this._session = ses;
    this._session.onEvent(this.send);
  }

  get session() {
    return null;
  }

  set api(api) {
    this._api = api;
  }

  get api() {
    return null;
  }

  set rtData(data) {
    this._rtData = data;
  }

  get rtData() {
    return null;
  }

  set eyeson(eyeson) {
    this._eyeson = eyeson;
  }

  get eyeson() {
    return null;
  }

  /**
   * Register a new listener.
   **/
  onReceive(callback) {
    Logger.debug('EventHandler::onReceive');
    this.listeners.push(callback);
  }

  /**
   * Unregisters a listener or all listeners.
   **/
  removeListener(callback) {
    if (!callback) {
      this.listeners.length = 0;
      return;
    }
    this.listeners = this.listeners.filter(listener => listener !== callback);
  }

  /**
   * On receiving an event, notify all registered listeners.
   **/
  /* eslint-disable camelcase */
  send(msg) {
    if (!msg.silenced) {
      Logger.debug('EventHandler::send', msg);
    }
    new ({
      stfu: ForwardEvent,
      chat: ReceiveChatEvent,
      accept: AcceptSessionEvent,
      podium: PodiumEvent,
      snapshot: SnapshotEvent,
      send_chat: SendChatEvent,
      set_layer: SetLayerEvent,
      set_layout: SetLayoutEvent,
      fetch_room: FetchRoomEvent,
      room_ready: RoomReceivedEvent,
      room_setup: RoomReceivedEvent,
      memberlist: ParticipantListUpdateEvent,
      upload_file: UploadFileEvent,
      delete_file: DeleteFileEvent,
      request_stfu: StfuEvent,
      change_stream: ChangeStreamEvent,
      replace_stream: ReplaceStreamEvent,
      toggle_camera: ToggleCameraEvent,
      stop_playback: StopPlaybackEvent,
      playback_update: ForwardEvent,
      start_playback: StartPlaybackEvent,
      start_stream: StartStreamEvent,
      stop_recording: StopRecordingEvent,
      start_screen_capture: StartScreenCaptureEvent,
      stop_broadcasts: StopBroadcastsEvent,
      start_recording: StartRecordingEvent,
      stop_presenting: StopPresentingEvent,
      start_presenting: StartPresentingEvent,
      stop_annotation: StopAnnotationEvent,
      start_annotation: StartAnnotationEvent,
      clear_front_layer: ClearFrontLayerEvent,
      request_guest_user: RequestGuestUserEvent,
      request_permalink_meeting_info: RequestPermalinkMeetingInfoEvent,
      voice_activity_raw: VoiceActivityEvent,
      start_mixer: StartMixerEvent,
      update_mixer: UpdateMixerEvent,
      lock_meeting: LockMeetingEvent,
      terminate_meeting: TerminateMeetingEvent,
      kick_user: KickUserEvent,
      session_termination: SessionTerminationEvent,
      start_media: StartMediaEvent,
      change_screen_video: ScreenVideoChangeEvent,
      stop_screen_video: ScreenVideoStoppedEvent,
      stop_rtmp: StopRtmpEvent,
      start_rtmp: StartRtmpEvent,
      suspend_virtual_background: VirtualBackgroundEvent,
      audio_device_ended: AudioDeviceEndEvent,
      video_device_ended: VideoDeviceEndEvent,
      all_devices_ended: AllDevicesEndEvent,
      start_pip_cam: StartPipCamEvent,
      stop_pip_cam: StopPipCamEvent,
      update_pip_cam: UpdatePipCamEvent,
      presentation_update: PresentationUpdateEvent,

      capture_error: ForwardEvent,
      voice_activity: ForwardEvent,
      options_update: ForwardEvent,
      ext_not_installed: ForwardEvent,
      broadcasts_update: ForwardEvent,
      presentation_ended: ForwardEvent,

      recording: IgnoreEvent,
      ext_cancel: IgnoreEvent,
      ext_installed: IgnoreEvent,
      message_status: IgnoreEvent,
      moderator_info: IgnoreEvent
    }[msg.type] || PublicEvent)(this).handle(msg);
  }
  /* eslint-enable camelcase */
}

export default EventHandler;
