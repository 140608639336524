import BaseEvent from './BaseEvent';

class ScreenVideoStoppedEvent extends BaseEvent {
  handle() {
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    if (_session.micMixer) {
      _session.micMixer.destroy();
      _session.micMixer = null;
    }
    _session.emit({ type: 'screen_video_ended' });
  }
}

export default ScreenVideoStoppedEvent;
