import BaseEvent from './BaseEvent';

const fixUrlEnd = url => {
  if (!url.endsWith('/')) {
    return url + '/';
  }
  return url;
};

class StartRtmpEvent extends BaseEvent {
  handle(msg) {
    const { _api, _session } = this.context;
    const streamUrl = fixUrlEnd(msg.url) + msg.key;
    _api.startBroadcast({ streamUrl }, 'generic').catch(error => {
      _session.emit({
        type: 'start_broadcast_error',
        platform: 'generic',
        error: error
      });
    });
  }
}

export default StartRtmpEvent;
