/* eslint-disable max-lines */
import Logger from '../Logger.js';

let webhidSupported = null;

const TELEPHONY_DEVICE_USAGE_PAGE = 0x0b;
const DEVICE_USAGE = {
  // output:
  0x080009: 'mute',
  0x080017: 'offHook',
  // input:
  0x0b0020: 'hookSwitch',
  0x0b002f: 'phoneMute'
};

const toHex16 = value => {
  // prettier-ignore
  return Number(value)
    .toString(16)
    .toUpperCase()
    .padStart(4, '0');
};

const gatherDeviceInfo = device => {
  const info = {
    hookSwitch: null,
    mute: null,
    offHook: null,
    phoneMute: null,
    allInputs: [],
    allOutputs: []
  };
  const parseReport = (report, list) => {
    if (!report.reportId || !Array.isArray(report.items)) {
      // no reportId or 0 is invalid
      return;
    }
    let bit = 0;
    report.items.forEach(item => {
      if (
        !(Reflect.has(item, 'reportCount') && Reflect.has(item, 'reportSize'))
      ) {
        return;
      }
      if (Array.isArray(item.usages)) {
        item.usages.forEach((usage, usageIndex) => {
          const entry = {
            usage: toHex16(usage),
            id: DEVICE_USAGE[usage],
            reportId: report.reportId,
            // prettier-ignore
            bit: bit + (usageIndex * item.reportSize),
            isAbsolute: Reflect.has(item, 'isAbsolute')
              ? item.isAbsolute
              : false
          };
          if (DEVICE_USAGE[usage]) {
            info[DEVICE_USAGE[usage]] = entry;
          }
          list.push(entry);
        });
      }
      bit += item.reportCount * item.reportSize;
    });
  };
  if (Array.isArray(device.collections)) {
    device.collections.forEach(collection => {
      if (
        !(collection && collection.usagePage === TELEPHONY_DEVICE_USAGE_PAGE)
      ) {
        return;
      }
      if (Array.isArray(collection.inputReports)) {
        collection.inputReports.forEach(report =>
          parseReport(report, info.allInputs)
        );
      }
      if (Array.isArray(collection.outputReports)) {
        collection.outputReports.forEach(report =>
          parseReport(report, info.allOutputs)
        );
      }
    });
  }
  return info;
};

const generateDeviceId = ({ vendorId, productId }) => {
  return `${toHex16(vendorId)}:${toHex16(productId)}`;
};

const createReportData = ({ bit }, value) => {
  const byteIndex = Math.trunc(bit / 8);
  const reportLength = byteIndex + 1;
  const reportData = new Uint8Array(reportLength);
  const bitPosition = bit % 8;
  // eslint-disable-next-line no-bitwise
  reportData[byteIndex] = value << bitPosition;
  return reportData;
};

const sendReport = async (device, item, value, verbose) => {
  if (item) {
    const data = createReportData(item, value);
    await device.sendReport(item.reportId, data);
    if (verbose) {
      Logger.debug(
        'WebHIDManager::sendReport',
        device.productName,
        item.id || item.usage,
        'reportId',
        item.reportId,
        'data',
        data[0]
      );
    }
  }
};

const resetDeviceReports = async (device, { allOutputs }, status, verbose) => {
  const promises = allOutputs.map(async item => {
    try {
      let value = 0;
      if (
        status &&
        ((item.id === 'offHook' && status.call.active) ||
          (item.id === 'mute' && status.mute.active))
      ) {
        value = 1;
      }
      await sendReport(device, item, value, verbose);
    } catch (error) {
      Logger.error(error);
    }
  });
  await Promise.allSettled(promises);
};

// eslint-disable-next-line max-statements
const hasStatusChanged = (item, reportId, data, status) => {
  if (!item || item.reportId !== reportId) {
    return false;
  }
  const byteIndex = Math.trunc(item.bit / 8);
  if (byteIndex >= data.byteLength) {
    return false;
  }
  const bitPosition = item.bit % 8;
  // eslint-disable-next-line no-bitwise
  const usageOn = (data.getUint8(byteIndex) & (1 << bitPosition)) !== 0;
  if (item.isAbsolute) {
    if (status.active !== usageOn) {
      status.active = usageOn;
      return true;
    }
  } else if (usageOn) {
    status.active = !status.active;
    return true;
  }
  return false;
};

/**
 * WebHIDManager. Handles call control devices and synchronises call and mute
 * states.
 * Attention! Do not call constructor - use static WebHIDManager.initialize
 *
 * @see https://docs.eyeson.com/blog/call-control-devices
 */
class WebHIDManager {
  // eslint-disable-next-line max-statements
  constructor(options) {
    const { verbose = true } = options || {};
    this.status = {
      call: { active: false },
      mute: { active: false }
    };
    this.verbose = verbose;
    this.devices = new Map();
    this.listeners = [];
    this.channel = null;
    this.boundOnInputReport = this.onInputReport.bind(this);
    this.boundOnBeforeUnload = this.onBeforeUnload.bind(this);
    this.boundOnDeviceConnect = this.onDeviceConnect.bind(this);
    this.boundOnDeviceDisconnect = this.onDeviceDisconnect.bind(this);
  }
  /**
   * WebHIDManager.supported
   *
   * @return {boolean} - support in current browser and frame
   */
  static get supported() {
    if (webhidSupported !== null) {
      return webhidSupported;
    }
    const allowed = Reflect.has(document, 'featurePolicy')
      ? document.featurePolicy.allowsFeature('hid')
      : true;
    webhidSupported = Reflect.has(navigator, 'hid') && allowed;
    return webhidSupported;
  }
  /**
   * Initialize WebHIDManager instance.
   *
   * @param {object} [options] - Currently just default { verbose: true }
   * @return {Promise<WebHIDManager>}
   */
  static async initialize(options) {
    const manager = new WebHIDManager(options);
    await manager.restrictMultipleInstances();
    if (!WebHIDManager.supported || manager.blocked) {
      manager.destroy();
      return manager;
    }
    navigator.hid.addEventListener('connect', manager.boundOnDeviceConnect);
    navigator.hid.addEventListener(
      'disconnect',
      manager.boundOnDeviceDisconnect
    );
    window.addEventListener('beforeunload', manager.boundOnBeforeUnload, {
      passive: true
    });
    manager.initDeviceList();
    return manager;
  }
  restrictMultipleInstances() {
    return new Promise(resolve => {
      if (typeof window.BroadcastChannel !== 'function') {
        resolve();
        return;
      }
      this.channel = new BroadcastChannel('eyeson-webhid-manager');
      this.channel.onmessage = ({ data }) => {
        if (data === 'hello') {
          this.channel.postMessage('stop');
        }
        if (data === 'stop') {
          this.blocked = true;
          resolve();
        }
      };
      this.channel.postMessage('hello');
      setTimeout(resolve, 100);
    });
  }
  /**
   * Register event handler
   *
   * @param {function} fn - Callback event handler
   */
  onEvent(fn) {
    if (typeof fn === 'function') {
      this.listeners.push(fn);
    }
  }
  /**
   * Remove event handler
   *
   * @param {function|undefined} fn - Callback event handler - leave empty to remove all handler
   */
  offEvent(fn) {
    if (typeof fn === 'function') {
      this.listeners = this.listeners.filter(cb => cb !== fn);
    } else {
      this.listeners.length = 0;
    }
  }
  emit(message) {
    this.listeners.forEach(fn => fn(message));
  }
  /**
   * Set mute active state. Synchronizes all devices
   *
   * @param {boolean} active - Mute active state
   */
  setMuteActive(active) {
    if (typeof active !== 'boolean') {
      Logger.error(
        'WebHIDManager::setMuteActive',
        '"Active" must be type boolean',
        active
      );
      return;
    }
    if (this.status.mute.active === active) {
      return;
    }
    this.status.mute.active = active;
    this.devices.forEach(async ({ deviceStatus, deviceInfo }, device) => {
      if (deviceStatus.mute.active !== active) {
        try {
          deviceStatus.mute.active = active;
          await sendReport(
            device,
            deviceInfo.mute,
            Number(active),
            this.verbose
          );
        } catch (error) {
          Logger.error('WebHIDManager::setMuteActive', error);
        }
      }
    });
  }
  /**
   * Set call active state. Synchronizes all devices
   *
   * @param {boolean} active - Call active state
   */
  setCallActive(active) {
    if (typeof active !== 'boolean') {
      Logger.error(
        'WebHIDManager::setCallActive',
        '"Active" must be type boolean',
        active
      );
      return;
    }
    if (this.status.call.active === active) {
      return;
    }
    this.status.call.active = active;
    this.devices.forEach(async ({ deviceStatus, deviceInfo }, device) => {
      if (deviceStatus.offHook.active !== active) {
        try {
          deviceStatus.offHook.active = active;
          await sendReport(
            device,
            deviceInfo.offHook,
            Number(active),
            this.verbose
          );
        } catch (error) {
          Logger.error('WebHIDManager::setCallActive', error);
        }
      }
    });
  }
  // eslint-disable-next-line max-statements
  async initDeviceList() {
    if (!WebHIDManager.supported || this.blocked) {
      return;
    }
    try {
      const devices = await navigator.hid.getDevices();
      if (this.verbose) {
        Logger.debug('WebHIDManager::initDeviceList', devices);
      }
      const initPromises = devices.map(device => this.initDevice(device));
      await Promise.allSettled(initPromises);
      this.emitDeviceList();
    } catch (error) {
      Logger.error('WebHIDManager::initDeviceList', error);
      this.emit({
        type: 'error',
        id: 'initialize_failed',
        message: 'WebHID initializing failed'
      });
    }
  }
  /**
   * Emit "devicelist" event
   */
  emitDeviceList() {
    const deviceList = [];
    this.devices.forEach(({ id }, device) => {
      deviceList.push({
        id,
        vendorId: toHex16(device.vendorId),
        productId: toHex16(device.productId),
        productName: device.productName
      });
    });
    this.emit({ type: 'devicelist', devices: deviceList });
  }
  /**
   * Trigger browser pair device request.
   *
   * @return {Promise}
   */
  // eslint-disable-next-line max-statements
  async pairDeviceRequest() {
    if (!WebHIDManager.supported || this.blocked) {
      return;
    }
    try {
      const [device] = await navigator.hid.requestDevice({
        filters: [{ usagePage: TELEPHONY_DEVICE_USAGE_PAGE }]
      });
      if (this.verbose) {
        Logger.debug('WebHIDManager::pairDeviceRequest', device);
      }
      if (!device) {
        return;
      }
      if ((await this.initDevice(device)) === false) {
        this.emit({
          type: 'error',
          id: 'invalid_device',
          message: 'Invalid device'
        });
        return;
      }
      this.emitDeviceList();
    } catch (error) {
      Logger.error('WebHIDManager::pairDeviceRequest', error);
      this.emit({
        type: 'error',
        id: 'pair_request_failed',
        message: 'Device pair request failed'
      });
    }
  }
  // eslint-disable-next-line max-statements
  async initDevice(device) {
    try {
      if (!device) {
        return false;
      }
      if (this.devices.has(device)) {
        if (!device.opened) {
          try {
            await device.open();
          } catch (error) {
            Logger.error('WebHIDManager::initDevice', error);
            return false;
          }
        }
        return true;
      }
      const deviceInfo = gatherDeviceInfo(device);
      if (this.verbose) {
        Logger.debug(
          'WebHIDManager::initDevice',
          device.productName,
          deviceInfo
        );
      }
      if (
        !(
          (deviceInfo.hookSwitch && deviceInfo.offHook) ||
          (deviceInfo.phoneMute && deviceInfo.mute)
        )
      ) {
        device.removeEventListener('inputreport', this.boundOnInputReport);
        try {
          await device.forget();
        } catch (error) {
          Logger.error('WebHIDManager::initDevice', error);
        }
        return false;
      }
      if (!device.opened) {
        await device.open();
      }
      this.devices.set(device, {
        id: generateDeviceId(device),
        deviceStatus: {
          offHook: { active: this.status.call.active },
          mute: { active: this.status.mute.active }
        },
        deviceInfo
      });
      await resetDeviceReports(device, deviceInfo, this.status, this.verbose);
      device.addEventListener('inputreport', this.boundOnInputReport);
    } catch (error) {
      Logger.error('WebHIDManager::initDevice', error);
      if (device) {
        device.removeEventListener('inputreport', this.boundOnInputReport);
        try {
          await device.forget();
        } catch (error2) {
          Logger.error(error2);
        }
        this.devices.delete(device);
      }
      return false;
    }
    return true;
  }
  onInputReport({ device, reportId, data }) {
    if (!this.devices.has(device)) {
      return;
    }
    const { deviceStatus, deviceInfo } = this.devices.get(device);
    if (
      this.verbose &&
      (reportId === deviceInfo.hookSwitch.reportId ||
        reportId === deviceInfo.phoneMute.reportId)
    ) {
      Logger.debug(
        'WebHIDManager::onInputReport',
        device.productName,
        'reportId',
        reportId,
        'data',
        data.getUint8(0)
      );
    }
    if (
      hasStatusChanged(
        deviceInfo.phoneMute,
        reportId,
        data,
        deviceStatus.mute,
        device
      )
    ) {
      this.onStatusChange(
        'togglemute',
        this.status.mute,
        deviceStatus.mute,
        'mute',
        device
      );
    }
    if (
      hasStatusChanged(
        deviceInfo.hookSwitch,
        reportId,
        data,
        deviceStatus.offHook,
        device
      )
    ) {
      this.onStatusChange(
        'togglecall',
        this.status.call,
        deviceStatus.offHook,
        'offHook',
        device
      );
    }
  }
  onStatusChange(type, status, deviceStatus, deviceInfoType, device) {
    if (status.active !== deviceStatus.active) {
      status.active = deviceStatus.active;
      this.devices.forEach(async (otherDeviceLookup, otherDevice) => {
        if (
          otherDevice !== device &&
          otherDeviceLookup.deviceStatus[deviceInfoType].active !==
            status.active
        ) {
          try {
            otherDeviceLookup.deviceStatus[deviceInfoType].active =
              status.active;
            await sendReport(
              otherDevice,
              otherDeviceLookup.deviceInfo[deviceInfoType],
              Number(status.active),
              this.verbose
            );
          } catch (error) {
            Logger.error('WebHIDManager::onStatusChange', error);
          }
        }
      });
      this.emit({ type, active: status.active });
    }
  }
  async onDeviceConnect({ device }) {
    if (this.verbose) {
      Logger.debug('WebHIDManager::onDeviceConnected', device);
    }
    // wait a bit to let device warm up
    await new Promise(resolve => setTimeout(resolve, 2000));
    await this.initDeviceList();
  }
  onDeviceDisconnect({ device }) {
    if (this.verbose) {
      Logger.debug('WebHIDManager::onDeviceDisconnected', device);
    }
    if (this.devices.has(device)) {
      this.devices.delete(device);
      this.emitDeviceList();
    }
  }
  /**
   *
   * @param {string} id - id of paired device "xxxx:xxxx"
   * @return {Promise}
   */
  // eslint-disable-next-line max-statements
  async removeDevice(id) {
    try {
      let device = null;
      let deviceInfo = null;
      Array.from(this.devices.entries()).some(entry => {
        if (entry[1].id === id) {
          // eslint-disable-next-line prefer-destructuring
          device = entry[0];
          // eslint-disable-next-line prefer-destructuring
          deviceInfo = entry[1].deviceInfo;
          return true;
        }
        return false;
      });
      if (!device) {
        return;
      }
      device.removeEventListener('inputreport', this.boundOnInputReport);
      if (deviceInfo) {
        await resetDeviceReports(device, deviceInfo, null, this.verbose);
      }
      try {
        await device.forget();
      } catch (error) {
        Logger.error(error);
      }
      this.devices.delete(device);
      this.emitDeviceList();
    } catch (error) {
      Logger.error('WebHIDManager::removeDevice', error);
    }
  }
  onBeforeUnload() {
    this.devices.forEach(({ deviceInfo }, device) => {
      device.removeEventListener('inputreport', this.boundOnInputReport);
      resetDeviceReports(device, deviceInfo, null, this.verbose);
    });
  }
  /**
   * Destroy WebHIDManager instance.
   */
  // eslint-disable-next-line max-statements
  destroy() {
    window.removeEventListener('beforeunload', this.boundOnBeforeUnload);
    if (WebHIDManager.supported) {
      navigator.hid.removeEventListener('connect', this.boundOnDeviceConnect);
      navigator.hid.removeEventListener(
        'disconnect',
        this.boundOnDeviceDisconnect
      );
    }
    this.offEvent();
    if (this.channel) {
      this.channel.onmessage = null;
      this.channel.close();
      this.channel = null;
    }
    this.onBeforeUnload();
    this.devices.clear();
    this.boundOnInputReport = null;
    this.boundOnBeforeUnload = null;
    this.boundOnDeviceConnect = null;
    this.boundOnDeviceDisconnect = null;
  }
}

export default WebHIDManager;
