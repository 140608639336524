/* eslint-disable max-lines */
import Logger from './Logger.js';
import { getModifiers } from './SDPModifiers.js';
import DeviceManager from './DeviceManager.js';
import FeatureDetector from './FeatureDetector.js';
import MediaStreamBuilder from './MediaStreamBuilder.js';
import VirtualBackgroundMixer from './VirtualBackgroundMixer.js';
import DeviceMonitor from './DeviceMonitor.js';
import {
  hasVideo,
  hasAudio,
  createExternalStream
} from './utils/StreamHelpers.js';

/**
 * Conference Session is starting a session from active connection,
 * delegating events to registered listeners.
 **/
class ConferenceSession {
  /* eslint-disable max-statements */
  constructor(connection, comApi, options = {}) {
    this.options = options;
    this.options.RTCConstraints = Object.assign(
      {},
      this.defaultRTCConstraints,
      { offerToReceiveVideo: !this.options.eco }
    );
    this.accepted = false;
    this.listeners = [];
    this.restarting = false;
    this.connection = connection;
    this.comApi = comApi;
    this.sipSession = null;
    this.startAttempts = 0;
    this.maxStartAttempts = 3;
    this.tryAuthUpdate = true;
    this.tryResume = true;
    this.tryWaitResume = true;
    this.resumeTimer = null;
    this.waitResumeTimer = null;

    this.end = this.end.bind(this);
    this.emit = this.emit.bind(this);
    this.setStream = this.setStream.bind(this);
    this.endSession = this.endSession.bind(this);
    this.initSession = this.initSession.bind(this);
    this.handleFailed = this.handleFailed.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleUnmute = this.handleUnmute.bind(this);
    this.restartSession = this.restartSession.bind(this);
    this.tryRecoveryFrom = this.tryRecoveryFrom.bind(this);
    this.terminateSession = this.terminateSession.bind(this);
    this.remoteDescriptionUpdate = this.remoteDescriptionUpdate.bind(this);
  }
  /* eslint-enable max-statements */

  get peerConnection() {
    if (this.sipSession && this.sipSession.sessionDescriptionHandler) {
      return this.sipSession.sessionDescriptionHandler.peerConnection;
    }
    return null;
  }

  get localStream() {
    if (this.sipSession && this.sipSession.sessionDescriptionHandler) {
      return this.sipSession.sessionDescriptionHandler.localStream;
    }
    return null;
  }

  get remoteStream() {
    if (this.sipSession && this.sipSession.sessionDescriptionHandler) {
      return this.sipSession.sessionDescriptionHandler.remoteStream;
    }
    return null;
  }

  get defaultRTCConstraints() {
    return { offerToReceiveAudio: true, offerToReceiveVideo: true };
  }

  /**
   * Request stream and invoke session start.
   **/
  start() {
    if (this.options.stream) {
      this.initExternalStream();
      return;
    }
    this.initDeviceMonitor();
    this.initVirtualBackground();
    new MediaStreamBuilder(this.options)
      .start()
      .then(this.initSession)
      .catch(this.handleFailed);
  }

  initExternalStream() {
    const audioOnly = !hasVideo(this.options.stream) && this.options.eco;
    const stream = createExternalStream(this.options.stream, audioOnly);
    if (!stream) {
      this.handleFailed({ name: 'invalid_stream' });
      return;
    }
    this.externalStream = stream;
    this.options.audio = hasAudio(stream);
    this.options.video = hasVideo(stream);
    this.initSession(this.externalStream);
  }

  initVirtualBackground() {
    const type = DeviceManager.getStoredVirtualBackgroundType();
    this.vbgMixer = new VirtualBackgroundMixer();
    this.vbgMixer.changeBackground(type);
    this.options.vbgMixer = this.vbgMixer;
  }

  initDeviceMonitor() {
    this.deviceMonitor = new DeviceMonitor();
    this.deviceMonitor.onEvent(msg => this.emit(msg));
    this.options.deviceMonitor = this.deviceMonitor;
  }

  buildSessionOptions(stream) {
    const { options } = this;
    if (options.sendOnly === true) {
      options.RTCConstraints = {};
    }
    return {
      stream: stream,
      sendOnly: options.sendOnly,
      SDPModifiers: getModifiers({
        sendOnly: options.sendOnly
      }),
      connection: this.connection,
      handleAccept: this.handleAccept,
      handleUnmute: this.handleUnmute,
      RTCConstraints: options.RTCConstraints,
      remoteDescriptionUpdate: this.remoteDescriptionUpdate,
      mediaOptions: { audio: options.audio, video: options.video }
    };
  }

  /**
   * Init session and start stream.
   **/
  // eslint-disable-next-line max-statements
  initSession(stream) {
    this.tryResume = true;
    this.tryAuthUpdate = true;
    this.tryWaitResume = true;
    const options = this.buildSessionOptions(stream);
    this.sipSession = this.connection.startSession(options);
    this.onUnmute(stream);
    this.sipSession.onEvent(event => {
      if (event.type === 'accepted') {
        this.adjustVideoPodium();
      } else if (event.type === 'resumed') {
        clearTimeout(this.resumeTimer);
        clearTimeout(this.waitResumeTimer);
        this.tryResume = true;
        this.tryAuthUpdate = true;
        this.tryWaitResume = true;
      } else if (event.type === 'terminated') {
        this.handleTermination(event.reason, event.code);
      }
    });
    if (this.externalStream) {
      this.connection.hasExternalStream = true;
    }

    if (window) {
      window.addEventListener(
        FeatureDetector.isIOSDevice() ? 'pagehide' : 'beforeunload',
        this.endSession
      );
    }
  }

  /**
   * End a conference session.
   **/
  end() {
    this.listeners = [];
    this.endSession();
    this.accepted = false;
    this.sipSession = null;
    this.connection = null;
  }

  /**
   * Before terminating the session, remove all listeners and stop all
   * streams.
   **/
  endSession() {
    Logger.debug('ConferenceSession::endSession');
    clearTimeout(this.resumeTimer);
    clearTimeout(this.waitResumeTimer);
    if (this.sipSession) {
      this.sipSession.removeAllListeners();
      this.terminateSession();
    }
    if (this.connection) {
      this.connection.close();
    }
    if (this.deviceMonitor) {
      this.deviceMonitor.destroy();
    }
  }
  /* eslint-enable max-statements */

  /**
   * Depending on the state of the session, this function may send a CANCEL
   * request, a non-2xx final response, a BYE request, or even no request at
   * all.
   * https://sipjs.com/api/0.7.0/session/#terminateoptions
   **/
  terminateSession() {
    Logger.debug('ConferenceSession::terminateSession');
    try {
      this.sipSession.terminate();
    } catch (error) {
      Logger.error(error);
    }
  }

  /**
   * Replace the active stream with newStream and emit 'stream_update' with the
   * new stream.
   *
   * In case of an error (as will be the case in current EDGE (May 2019))
   * report back the current streams.
   **/
  // eslint-disable-next-line max-statements
  async setStream(newStream) {
    if (!this.sipSession) {
      return null;
    }
    try {
      const { sessionDescriptionHandler } = this.sipSession;
      const streams = await sessionDescriptionHandler.setStream(newStream);
      this.stopPresentingSet = false;
      if (this.micMixer && this.micMixer.active) {
        streams.newStream = this.micMixer.getMicOnlyStream();
        sessionDescriptionHandler.localStream = streams.newStream;
      }
      if (this.videoPlayer && this.videoPlayer.isStreaming()) {
        streams.newStream = this.videoPlayer.getMicOnlyStream();
        sessionDescriptionHandler.localStream = streams.newStream;
      }
      this.onUnmute(streams.newStream);
      this.emit({
        type: 'stream_update',
        localStream: streams.newStream,
        stream: streams.remoteStream
      });
      return streams.newStream;
    } catch (error) {
      Logger.error('ConferenceSession::setStream', error);
      this.emit({
        type: 'stream_update',
        localStream: this.localStream,
        stream: this.remoteStream
      });
      return this.localStream;
    }
  }

  /**
   * Trigger local track unmuted event.
   * Used to prevent iOS issue black video after incoming call.
   */
  onUnmute(stream) {
    if (stream) {
      stream.getTracks().forEach(track => {
        track.onunmute = () =>
          this.emit({ type: 'local_track_unmuted', track: track });
      });
    }
  }

  /**
   * Register a session monitor.
   **/
  setMonitor(monitor) {
    this.monitor = monitor;
  }

  /**
   * Forward accept event, build expected message format.
   **/
  handleAccept(remoteStream) {
    if (this.monitor) {
      this.monitor.observe(this.peerConnection);
    }
    if (!this.accepted) {
      this.emit({ type: 'accept', session: this.sipSession });
      this.accepted = true;
    }
    if (remoteStream) {
      this.emit({ type: 'stream_update', stream: remoteStream });
    }
  }

  handleUnmute(track) {
    this.emit({ type: 'track_unmuted', track: track });
  }

  remoteDescriptionUpdate(sdpWrapper) {
    Logger.debug('ConferenceSession::remoteDescriptionUpdate', sdpWrapper.sdp);
    let sfu = false;

    const splitSDP = sdpWrapper.sdp.split('\r\n');
    const sfuLine = splitSDP.find(line => line.startsWith('a=sfu-mode'));

    if (sfuLine) {
      sfu = sfuLine.includes('on');
    }

    this.emit({ type: 'remote_description_update', update: { sfu: sfu } });
  }

  /**
   * Once we have accepted the session, we need to adjust the video podium,
   * according to the current options.
   **/
  adjustVideoPodium() {
    this.send({ type: 'mute_video', on: !this.options.video });
  }

  /**
   * On Termination log to debug what happened.
   **/
  // eslint-disable-next-line max-statements
  handleTermination(reason, code) {
    Logger.debug('ConfSession::handleTermination', reason, code);
    clearTimeout(this.resumeTimer);
    clearTimeout(this.waitResumeTimer);
    if ((reason === 'bye' && code === 200) || reason === 'terminate') {
      this.handleExit();
    } else if (reason === 'disconnect') {
      if (code === -1) {
        if (this.tryAuthUpdate) {
          this.tryAuthUpdate = false;
          this.comApi.getRoom(data =>
            this.connection.updateAuthAndRestartSession(data)
          );
          return;
        } else if (this.tryWaitResume && this.connection) {
          this.tryWaitResume = false;
          this.waitResumeTimer = setTimeout(() => {
            this.comApi.getRoom(data => {
              if (this.connection) {
                this.connection.updateAuthAndResume(data);
              }
            });
          }, 5000);
          this.resumeTimer = setTimeout(() => {
            this.handleFailed({ name: 503 });
          }, 15000);
          return;
        }
        this.handleFailed({ name: 503 });
      } else {
        if (this.tryResume && this.connection) {
          this.tryResume = false;
          this.tryAuthUpdate = false;
          this.comApi.getRoom(data => {
            if (this.connection) {
              this.connection.updateAuthAndResume(data);
            }
          });
          this.resumeTimer = setTimeout(
            () => this.handleFailed({ name: 410 }),
            10000
          );
          return;
        }
        this.handleFailed({ name: 410 });
      }
    } else {
      this.handleFailed({ name: code });
    }
    if (!this.restarting) {
      this.emit({ type: 'session_termination' });
    }
  }

  /**
   * Forward exit event
   **/
  handleExit() {
    this.emit({ type: 'exit', reason: 'bye' });
  }

  /**
   * Forward failed event unless we attempt a recovery.
   **/
  handleFailed(reason) {
    Logger.warn('ConferenceSession::handleFailed: ', reason);
    clearTimeout(this.resumeTimer);
    clearTimeout(this.waitResumeTimer);
    let key = 'Desert';
    if (reason && reason.name) {
      key = reason.name;
    }

    if (this.tryRecoveryFrom(key)) {
      return;
    }

    const name =
      {
        NotFoundError: 'devices',
        NotAllowedError: 'permission',
        DevicesNotFoundError: 'devices',
        PermissionDeniedError: 'permission',
        NotReadableError: 'not_readable',
        403: 'session_in_use',
        410: 'abrupt_disconnect',
        413: 'request_too_large',
        426: 'ice_error',
        486: 'session_in_use',
        607: 'meeting_locked'
      }[key] || 'session_failed';

    this.emit({
      type: 'error',
      name: name,
      code: this.errorCodeName(key, name)
    });
  }

  errorCodeName(key, name) {
    if (
      [
        'permission',
        'devices',
        'not_readable',
        'meeting_locked',
        'session_in_use',
        'transport_error'
      ].includes(name)
    ) {
      return '';
    }
    return String(key);
  }

  /**
   * Conference session may recover from `failure` if a recovery for that
   * `failure` is known and its condition applies.
   **/
  tryRecoveryFrom(failure) {
    Logger.debug('ConferenceSession::tryRecoveryFrom: ', failure);

    const recovery = {
      404: {
        condition: () => this.startAttempts < this.maxStartAttempts,
        action: () => this.restartSession()
      }
    }[failure];

    if (this.startAttempts >= this.maxStartAttempts) {
      this.restarting = false;
      return false;
    }

    return Boolean(recovery && recovery.condition() && recovery.action());
  }

  /**
   * End the previously started session, but keep already registered listeners.
   **/
  restartSession() {
    Logger.debug('ConferenceSession::restartSession: ', this.startAttempts);
    this.restarting = true;
    this.startAttempts += 1;

    setTimeout(() => {
      this.endSession();
      this.start();
    }, 1000);

    return true;
  }

  /**
   * Register event listeners.
   **/
  onEvent(callback) {
    this.listeners.push(callback);
  }

  /**
   * On receiving an event, notify all registered listeners.
   **/
  emit(msg) {
    this.listeners.forEach(listener => listener(msg));
  }

  /**
   * Transport a message over the connection.
   **/
  send(msg) {
    return this.connection.send(msg);
  }
}

export default ConferenceSession;

/* eslint-enable max-lines */
