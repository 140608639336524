import Logger from '../Logger.js';
import BaseEvent from './BaseEvent';
import MediaStreamBuilder from '../MediaStreamBuilder.js';
import MicMixer from '../MicMixer.js';
import { getScreenAsVideoTracks } from '../utils/StreamHelpers.js';

const destroySessionMicMixer = session => {
  if (session.micMixer) {
    session.micMixer.destroy();
    session.micMixer = null;
  }
};

const getScreenErrorName = error => {
  let name = `error_Screen_${error.name}`;
  if (error.message.indexOf('not start audio') > -1) {
    name = `error_Screen_${error.name}_Audio`;
  }
  return name;
};

class ScreenVideoChangeEvent extends BaseEvent {
  // eslint-disable-next-line max-statements
  async handle({ surface }) {
    let screenStream = null;
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    try {
      const mediaStreamBuilder = new MediaStreamBuilder({ surface });
      screenStream = await mediaStreamBuilder.getDisplayMedia();
    } catch (error) {
      Logger.error('ScreenVideoChangeEvent', error);
      _session.emit({ type: 'warning', name: getScreenErrorName(error) });
      return;
    }
    destroySessionMicMixer(_session);
    _session.micMixer = new MicMixer();
    const options = {
      audio: _session.localStream.getAudioTracks()[0].enabled,
      video: false,
      screen: true,
      surface,
      micMixer: _session.micMixer,
      screenStream
    };
    try {
      const stream = await new MediaStreamBuilder(options).start();
      const [screenTrack] = getScreenAsVideoTracks(stream);
      if (screenTrack) {
        screenTrack.addEventListener('ended', () => {
          _session.emit({ type: 'stop_screen_video' });
        });
      }
      _session.setStream(stream);
    } catch (error) {
      Logger.error('ScreenVideoChangeEvent', error);
      destroySessionMicMixer(_session);
      _session.emit({
        type: 'screen_capture_error',
        name: getScreenErrorName(error)
      });
      _session.emit({ type: 'screen_video_ended' });
    }
  }
}

export default ScreenVideoChangeEvent;
