import FeatureDetector from './FeatureDetector.js';

/**
 * Add session attribute to disable sfu mode from the client.
 **/
// eslint-disable-next-line max-statements
const addSessionAttributes = sdp => {
  // double check config.allowSafariSFU on runtime
  const splitSDP = sdp.split('\r\n');
  const tLineIdx = splitSDP.findIndex(line => line.startsWith('t='));
  let addIndex = 1;
  if (FeatureDetector.canSFU()) {
    // insert sfuLine after tLine (timing), delete 0 elements
    splitSDP.splice(tLineIdx + addIndex, 0, 'a=sfu-capable');
    addIndex++;
    if (FeatureDetector.disallowAudioSyncSrcChange()) {
      splitSDP.splice(tLineIdx + addIndex, 0, 'a=disallow-audio-ssrc-change');
      addIndex++;
    }
  }
  if (FeatureDetector.canDataChannel()) {
    splitSDP.splice(
      tLineIdx + addIndex,
      0,
      'a=eyeson-datachan-capable',
      'a=eyeson-datachan-keepalive',
      'a=eyeson-sepp-messaging'
    );
  }
  return splitSDP.join('\r\n');
};

// eslint-disable-next-line max-statements
const addStereo = sdp => {
  const stereo = FeatureDetector.canStereo();
  const codecMatch = sdp.match(/^a=rtpmap:(\d+) opus/m);
  if (codecMatch) {
    const fmtpRegExp = new RegExp(`^a=fmtp:${codecMatch[1]} (.+)$`, 'm');
    const fmtpMatch = sdp.match(fmtpRegExp);
    if (fmtpMatch) {
      const options = {};
      fmtpMatch[1].split(';').forEach(entry => {
        const [key, value] = entry.split('=');
        options[key] = value;
      });
      options['stereo'] = stereo ? '1' : '0';
      // options['sprop-stereo'] = '1';
      const result = Object.entries(options)
        .map(([key, value]) => `${key}=${value}`)
        .join(';');
      return sdp.replace(fmtpRegExp, `a=fmtp:${codecMatch[1]} ${result}`);
    }
  }
  return sdp;
};

const sendOnly = sdp => {
  const lines = sdp.split('\r\n');
  let doIt = false;
  lines.forEach((line, row) => {
    if (line.startsWith('m=audio') || line.startsWith('m=video')) {
      doIt = true;
    } else if (line.startsWith('m=application')) {
      doIt = false;
    } else if (line === 'a=sendrecv' && doIt) {
      lines[row] = 'a=sendonly';
    }
  });
  return lines.join('\r\n');
};

const getModifiers = options => {
  const modifiers = {
    active: [addStereo],
    passive: [addSessionAttributes]
  };
  if (options.sendOnly === true) {
    modifiers.active.push(sendOnly);
  }
  return modifiers;
};

export { getModifiers };
