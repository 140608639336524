/* eslint-disable max-lines */
import FeatureDetector from '../FeatureDetector.js';
import Logger from '../Logger';

const CanvasMedia =
  window.CanvasCaptureMediaStream || window.CanvasCaptureMediaStreamTrack;

/**
 * Check if media stream has audio and audio track is enabled
 *
 * @param {MediaStream} stream
 * @return {boolean}
 */
const hasAudio = stream => {
  return Boolean(
    stream &&
      stream.getAudioTracks().length > 0 &&
      stream.getAudioTracks()[0].enabled
  );
};

/**
 * Check if media stream has video and video track is enabled
 *
 * @param {MediaStream} stream
 * @return {boolean}
 */
const hasVideo = stream => {
  return Boolean(
    stream &&
      stream.getVideoTracks().length > 0 &&
      stream.getVideoTracks()[0].enabled
  );
};

/**
 * Disable audio (=mute)
 *
 * @param {MediaStream} stream
 * @return {MediaStream}
 */
const disableAudio = stream => {
  if (stream) {
    stream.getAudioTracks().forEach(track => (track.enabled = false));
  }
  return stream;
};

/**
 * Enable audio (=unmute)
 *
 * @param {MediaStream} stream
 * @return {MediaStream}
 */
const enableAudio = stream => {
  if (stream) {
    stream.getAudioTracks().forEach(track => (track.enabled = true));
  }
  return stream;
};

/**
 * Toggle audio (=mute/unmute)
 *
 * @param {MediaStream} stream
 * @param {boolean} enabled
 * @return {MediaStream}
 */
const toggleAudio = (stream, enabled) => {
  if (stream) {
    stream.getAudioTracks().forEach(track => (track.enabled = enabled));
  }
  return stream;
};

/**
 * Toggle video
 *
 * @param {MediaStream} stream
 * @param {boolean} enabled
 * @return {MediaStream}
 */
const toggleVideo = (stream, enabled) => {
  if (stream) {
    stream.getVideoTracks().forEach(track => (track.enabled = enabled));
  }
  return stream;
};

/**
 * In order to be "informed" about track ending in compose_canvas where we
 * create offscreen/hidden video elements and need to clean them up afterwards,
 * we need to dispatch 'stopped' ourselves. The 'ended' event is not emitted
 * after stopping the track, as one might expect see:
 * @see https://stackoverflow.com/q/55953038/980524
 *
 * @param {MediaStreamTrack} track
 * @return {boolean} dispatchEvent return value
 */
const stopTrack = track => {
  track.stop();
  return track.dispatchEvent(new Event('stopped'));
};

/**
 * Stop stream if exist
 *
 * @param {MediaStream} stream
 */
const stopStream = stream => {
  if (stream) {
    stream.getTracks().forEach(stopTrack);
  }
};

const isScreenTrack = track => {
  return (
    /screen|monitor|window|web-contents-media-stream/i.test(track.label) ||
    track.type === 'screen-track' ||
    track.type === 'screen-video-track'
  );
};

const isScreenPresentationTrack = track => {
  return isScreenTrack(track) && track.type !== 'screen-video-track';
};

const isScreenAsVideoTrack = track => {
  return isScreenTrack(track) && track.type === 'screen-video-track';
};

const isCanvasTrack = track => {
  return Boolean(
    (track.type === 'canvas-track' || track instanceof CanvasMedia) &&
      track.canvas &&
      track.canvas.id !== 'eyeson-ninja-stream' &&
      track.canvas.id !== 'eyeson-vbg-stream'
  );
};

const isNinjaStreamTrack = track => {
  return Boolean(
    (track.type === 'canvas-track' || track instanceof CanvasMedia) &&
      track.canvas &&
      track.canvas.id === 'eyeson-ninja-stream'
  );
};

const isVbgStreamTrack = track => {
  return Boolean(
    (track.type === 'canvas-track' || track instanceof CanvasMedia) &&
      track.canvas &&
      track.canvas.id === 'eyeson-vbg-stream'
  );
};

const isCameraTrack = track => {
  return (
    !isCanvasTrack(track) && !isScreenTrack(track) && !isVbgStreamTrack(track)
  );
};

const getScreenTracks = stream => {
  if (!stream) {
    return [];
  }
  return stream.getVideoTracks().filter(isScreenTrack);
};

const getScreenPresentationTracks = stream => {
  if (!stream) {
    return [];
  }
  return stream.getVideoTracks().filter(isScreenPresentationTrack);
};

const getScreenAsVideoTracks = stream => {
  if (!stream) {
    return [];
  }
  return stream.getVideoTracks().filter(isScreenAsVideoTrack);
};

const getCameraTracks = stream => {
  if (!stream) {
    return [];
  }
  return stream.getVideoTracks().filter(isCameraTrack);
};

const getVbgTracks = stream => {
  if (!stream) {
    return [];
  }
  return stream.getVideoTracks().filter(isVbgStreamTrack);
};

const stopCamera = stream => {
  getCameraTracks(stream).forEach(stopTrack);
};

const disableCamera = stream => {
  getCameraTracks(stream).forEach(track => (track.enabled = false));
  return stream;
};

const enableCamera = stream => {
  getCameraTracks(stream).forEach(track => (track.enabled = true));
  return stream;
};

const toggleCamera = (stream, enabled) => {
  getCameraTracks(stream).forEach(track => (track.enabled = enabled));
  return stream;
};

const toggleVbgTrack = (stream, enabled) => {
  getVbgTracks(stream).forEach(track => (track.enabled = enabled));
  return stream;
};

const getCanvasTracks = stream => {
  // current FF implementation
  if (stream instanceof CanvasMedia) {
    return stream.getVideoTracks();
  }
  if (!stream) {
    return [];
  }
  return stream.getVideoTracks().filter(isCanvasTrack);
};

const isScreenStream = stream => {
  return hasVideo(stream) && stream.getVideoTracks().some(isScreenTrack);
};

const isScreenPresentationStream = stream => {
  return (
    hasVideo(stream) && stream.getVideoTracks().some(isScreenPresentationTrack)
  );
};

const isCanvasStream = stream => {
  if (!FeatureDetector.hasCanvasCaptureSupport() || !stream) {
    return false;
  }

  return stream.getVideoTracks().some(isCanvasTrack);
};

const isVBGStream = stream => {
  if (!FeatureDetector.hasCanvasCaptureSupport() || !stream) {
    return false;
  }
  return hasVideo(stream) && stream.getVideoTracks().some(isVbgStreamTrack);
};

const isCameraStream = stream => {
  return (
    hasVideo(stream) &&
    !isScreenStream(stream) &&
    !isCanvasStream(stream) &&
    !isVBGStream(stream)
  );
};

const isCanvasPresentationStream = stream => {
  if (!stream) {
    return false;
  }
  return isCanvasStream(stream) && stream.getVideoTracks().some(isCanvasTrack);
};

const isPresentationStream = stream => {
  return isScreenPresentationStream(stream) || isCanvasStream(stream);
};

const hasCameraVideo = stream => {
  return getCameraTracks(stream).some(track => track.enabled);
};

const getFacingMode = stream => {
  const tracks = getCameraTracks(stream);
  if (tracks.length > 0) {
    const [track] = tracks;
    if (typeof track.getSettings === 'function') {
      const settings = track.getSettings();
      if (settings && 'facingMode' in settings) {
        return settings.facingMode;
      }
    }
  }
  return null;
};

// try to make FF's captureStream handling track based.
const captureStream = canvas => {
  // NOTE: getContext is needed for firefox, otherwise we get an exception:
  // NS_ERROR_NOT_INITIALIZED when calling .captureStream on the canvas.
  // https://bugzilla.mozilla.org/show_bug.cgi?id=1257440
  canvas.getContext('2d');

  // 0 param is fps, but since that doesn't work cross browser, we use 0
  // and request frames manually.
  const stream = canvas.captureStream(20);
  const [track] = stream.getVideoTracks();
  if (!('requestFrame' in track)) {
    track.type = 'canvas-track';
    track.canvas = canvas;
    track.requestFrame = () => stream.requestFrame();
  }

  return stream;
};

// eslint-disable-next-line max-statements
const createFakeAudioTrack = () => {
  try {
    const ac = new (window.AudioContext || window.webkitAudioContext)();
    const { stream } = ac.createMediaStreamDestination();
    const [track] = stream.getAudioTracks();
    track.type = 'fake-audio';
    track.enabled = false;
    stopStream(stream);
    ac.close();
    return track;
  } catch (error) {
    Logger.warn(
      'StreamHelpers::createFakeAudioTrack Unable to create audio track',
      error
    );
  }
  return null;
};

// eslint-disable-next-line max-statements
const createFakeVideoTrack = () => {
  try {
    const canvas = document.createElement('canvas');
    // Firefox needs some kind of kickass
    canvas.getContext('2d').fillRect(0, 0, canvas.width, canvas.height);
    const stream = canvas.captureStream();
    const [track] = stream.getVideoTracks();
    track.type = 'fake-video';
    track.enabled = false;
    stopStream(stream);
    return track;
  } catch (error) {
    Logger.warn(
      'StreamHelpers::createFakeVideoTrack Unable to create video track',
      error
    );
  }
  return null;
};

/**
 * Generates a new MediaStream from a given external MediaStream and adds
 * missing audio or video track.
 *
 * @param {MediaStream} stream - External media stream
 * @param {boolean} [audioOnly] - Skip video for Eyeson Audio-only mode
 * @return {MediaStream|false} - Created MediaStream or false
 */
// eslint-disable-next-line max-statements
const createExternalStream = (stream, audioOnly = false) => {
  if (
    stream &&
    stream instanceof MediaStream &&
    stream.getTracks().length > 0
  ) {
    const aTrack = stream
      .getAudioTracks()
      .find(track => track.readyState === 'live');
    const vTrack = stream
      .getVideoTracks()
      .find(track => track.readyState === 'live');
    const audioTrack = aTrack || createFakeAudioTrack();
    const videoTrack = vTrack || createFakeVideoTrack();
    const tracks = [];
    if (audioTrack) {
      tracks.push(audioTrack);
    }
    if (videoTrack && !audioOnly) {
      tracks.push(videoTrack);
    }
    return new MediaStream(tracks);
  }
  return false;
};

export {
  hasAudio,
  hasVideo,
  disableAudio,
  enableAudio,
  toggleAudio,
  toggleVideo,
  stopTrack,
  stopStream,
  isScreenAsVideoTrack,
  isNinjaStreamTrack,
  isVbgStreamTrack,
  getScreenTracks,
  getScreenPresentationTracks,
  getScreenAsVideoTracks,
  getCameraTracks,
  getVbgTracks,
  stopCamera,
  disableCamera,
  enableCamera,
  toggleCamera,
  toggleVbgTrack,
  getCanvasTracks,
  isScreenStream,
  isScreenPresentationStream,
  isCanvasStream,
  isVBGStream,
  isCameraStream,
  isCanvasPresentationStream,
  isPresentationStream,
  hasCameraVideo,
  getFacingMode,
  captureStream,
  createExternalStream
};
